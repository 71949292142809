import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const StyledWrapper = styled.div`
  ${({ theme }) => theme.mq.desktop} {
    max-width: ${({ nrOfItems }) => (nrOfItems < 3 ? '400px' : '1000px')};
  }
  margin: 0 auto;
`;

const ImageGridStyles = styled.div`
  display: grid;
  grid-gap: 2px;
  position: relative;
  justify-content: center;
  align-content: center;
  align-items: center;

  ${({ theme }) => theme.mq.desktop} {
    grid-template-columns: repeat(
      ${({ nrOfItems }) => nrOfItems},
      minmax(200px, 1fr)
    );
  }
`;

function isGalleryType(galleryTypes, title) {
  return galleryTypes.find((type) => type.title === title);
}

const StyledImage = styled(GatsbyImage)`
  width: 25rem;
  height: 25rem;
  object-fit: cover;

  ${({ theme }) => theme.mq.desktop} {
    width: 100%;
  }
`;

const SingleImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const SingleOffersGallery = ({ offerTitle }) => {
  const data = useStaticQuery(graphql`
    query SingleOffersGalleryQuery {
      allGalleries: allSanityGallery {
        nodes {
          image {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          id
          name
          imageType {
            slug {
              current
            }
            title
            id
          }
        }
      }
    }
  `);


  const images = data.allGalleries.nodes
    .filter((img) => isGalleryType(img.imageType, offerTitle))
    .slice(0, 3);

  return (
    <>
      <StyledWrapper>
        <ImageGridStyles nrOfItems={images.length}>
          {images.map((image) => (
            <SingleImageWrapper key={image.id}>
              <StyledImage image={image.image.asset.gatsbyImageData} alt={image.id} />
            </SingleImageWrapper>
          ))}
        </ImageGridStyles>
      </StyledWrapper>
    </>
  );
};

export default SingleOffersGallery;
