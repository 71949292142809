import React from 'react';
import styled from 'styled-components';
import {graphql, Link, useStaticQuery} from 'gatsby';
import {GatsbyImage} from "gatsby-plugin-image";
import file from '../assets/images/lavender.svg';

const StyledOffers = styled.div`
  ${({theme}) => theme.mq.desktop} {
    padding: 2rem 40rem 2rem 40rem;
  }
`;

const OffersWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;

  ${({theme}) => theme.mq.desktop} {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, minmax(30rem, 1fr));
    grid-template-rows: repeat(2, minmax(30rem, 1fr));
  }
`;

const OfferWrapper = styled.div`
  filter: drop-shadow(5px 5px 5px #fff);
`;

const HoverImage = styled.img`
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 180px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  ${({theme}) => theme.mq.tablet} {
    max-width: 250px;
  }

  ${OfferWrapper}:hover & {
    opacity: 1;
    transition: opacity 0.5s ease;
    will-change: opacity;
  }
`;

const OfferPhotoOuterWrapper = styled.div`
  align-items: center;
  justify-content: center;
  position: relative;
`;

const OfferPhotoInnerWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 3rem 6rem rgba(0, 0, 0, 0.1);

  ${({theme}) => theme.mq.tablet} {
    width: 200px;
    height: 200px;
  }
`;

const OfferContent = styled.div`
  padding: 0 3rem 3rem 3rem;
  text-align: center;
`;

const StyledOfferTitle = styled.p`
  font-weight: 600;
  margin-bottom: 1rem;
  font-family: 'Dancing Script', cursive;
  font-size: ${({theme}) => theme.font.size.s};

  ${({theme}) => theme.mq.tablet} {
    font-size: ${({theme}) => theme.font.size.m};
  }
`;

const StyledLinkToOffer = styled(Link)`
  color: #59388f;
  font-family: 'Dancing Script', cursive;
  font-size: ${({theme}) => theme.font.size.s};
  padding: 1rem;

  ${({theme}) => theme.mq.tablet} {
    font-size: ${({theme}) => theme.font.size.m};
  }
`;

const OfferPhoto = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const StyledOfferItem = styled.span`
  margin: 0;
  padding: 0;
  font-size: ${({theme}) => theme.font.size.xs};

  :after {
    content: '/ ';
  }

  &:last-child {
    :after {
      content: '';
    }
  }
`;

const Offers = () => {
    const data = useStaticQuery(graphql`{
      allOffers: allSanityOffer {
        nodes {
          image {
            asset {
                gatsbyImageData
            }
          }
          id
          listOfOffers
          slug {
            current
          }
          title
          headline
          description
        }
      }
      }
    `);

    return (
        <StyledOffers>
            <OffersWrapper>
                {data.allOffers.nodes.map((offer) => (
                    <OfferWrapper key={offer.id}>
                        <div>
                            <OfferPhotoOuterWrapper>
                                <Link to={`/oferta/${offer.slug.current}`}>
                                    <HoverImage src={file} alt=""/>
                                    <OfferPhotoInnerWrapper>
                                        <OfferPhoto
                                            image={offer.image.asset.gatsbyImageData}
                                            alt={offer.title}
                                        />
                                    </OfferPhotoInnerWrapper>
                                </Link>
                            </OfferPhotoOuterWrapper>
                            <OfferContent>
                                <StyledOfferTitle>{offer.title}</StyledOfferTitle>
                                <div>
                                    {offer.listOfOffers.map((item) => (
                                        <StyledOfferItem key={item}>{item} </StyledOfferItem>
                                    ))}
                                </div>
                                <StyledLinkToOffer to={`/oferta/${offer.slug.current}`}>
                                    czytaj więcej
                                </StyledLinkToOffer>
                            </OfferContent>
                        </div>
                    </OfferWrapper>
                ))}
            </OffersWrapper>
        </StyledOffers>
    );
};

export default Offers;
