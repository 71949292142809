import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: 'Dancing Script', cursive;
  font-size: ${({ theme }) => theme.font.size.s};
  background-color: ${({ theme }) => theme.primaryFloristic};
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  color: black;
  transition: all 0.5s;
  position: relative;
  margin: 0 auto;

  &.white {
    background-color: ${({ theme }) => theme.white};
  }

  ${({ theme }) => theme.mq.desktop} {
    margin: 0;
    font-size: ${({ theme }) => theme.font.size.m};
  }

  &:hover {
    background-color: white;

    ::before {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    ::after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
  }

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid #ababab;
    transform: scale(1.2, 1.2);
  }
`;

export default StyledButton;
