import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import StyledButton from '../../components/Button';
import ContactFormCom from '../../components/ContactFormCom';
import Offers from '../../components/Offers';
import SEO from '../../components/SEO';
import SingleOffersGallery from '../../components/SingleOffersGallery';

const HeroStyledWrapper = styled.div`
  position: relative;
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: 100px;
`;

const HeroStyledHeaders = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;

const HeroStyledHeader = styled.h1`
  position: relative;
  z-index: 9997;
  color: ${({ theme }) => theme.white};
  font-size: ${({ theme }) => theme.font.size.m};
  font-family: 'Dancing Script', cursive;
  line-height: 2.2rem;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.xxxl};
  }
`;

const HeroStyledImage = styled(GatsbyImage)`
  position: relative;
  height: 30vh;
  width: 100%;
  opacity: 0.8;
`;

const TextStyledWrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 50px;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.desktop} {
    margin: 50px 300px 0 300px;
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const OffersWrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryFloristic};
  padding: 2rem;
  margin: 2rem 0;
  text-align: center;
  position: relative;
`;

const StyledTitle = styled.h2`
  font-weight: 600;
  font-size: ${({ theme }) => theme.font.size.s};
  text-transform: uppercase;
  text-shadow: 1px 1px 2px lightgrey;
  font-family: 'Dancing Script', cursive;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const StyledTitleOffers = styled(StyledTitle)`
  padding: 1.5rem 0 0 0;
  text-align: center;
`;

const DescriptionWrapper = styled.div`
  line-height: 2.2rem;
  max-width: 800px;
  margin: 2em auto;
`;

const StyledH3 = styled.h3`
  color: #59388f;
  text-decoration: underline;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const StyledParagraph = styled.p`
  margin: 4rem 0;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Dancing Script', cursive;
  line-height: 4rem;
  font-size: ${({ theme }) => theme.font.size.s};

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

export default function SzkolaCosinus({ data }) {
  return (
    <>
      <SEO title="Policealna Szkoła Cosinus" />
      <HeroStyledWrapper>
        <HeroStyledHeaders>
          <HeroStyledHeader>{data.singleOffer.title}</HeroStyledHeader>
        </HeroStyledHeaders>
        <HeroStyledImage image={data.singleOffer.image.asset.gatsbyImageData} />
      </HeroStyledWrapper>
      <TextStyledWrapper>
        <DescriptionWrapper>
          <StyledParagraph>
            Od lutego 2021 roku uczę studentów sztuki układania, komponowania
            kwiatów i innych roślin w{' '}
            <a
              href="https://www.cosinus.pl/szkola-policealna/florysta"
              target="_blank"
              rel="noreferrer"
            >
              Policealnej Szkole COSINUS w Gnieźnie
            </a>
            . Wszyscy z mojej grupy wiosennej z 2021 roku zdali egzamin
            państwowy i otrzymali tytuł zawodowy florysty.
          </StyledParagraph>
          <StyledParagraph>
            <b>
              Na zajęciach na kierunku Florystyka nauczysz się znajomości
              roślin, rozwijania wrażliwości i wyobraźni plastycznej, tworzenia
              kompozycji kwiatowych, wiązanek okolicznościowych, a także poznasz
              zasady tworzenia dekoracji roślin.
            </b>
          </StyledParagraph>
          <StyledParagraph>
            Zajęcia w szkole odbywają się w weekendy, a cała nauka trwa 1 rok i
            jest całkowicie darmowa.
          </StyledParagraph>
          <StyledParagraph>
            Jako florysta możesz pracować w kwiaciarniach, firmach
            organizujących wystrój, prowadzić działalność gospodarczą świadczącą
            usługi aranżacji wnętrz czy ogrodów za pomocą dekoracji roślinnych.
          </StyledParagraph>
          <StyledParagraph>
            <StyledH3>
              <a
                href="https://www.cosinus.pl/gniezno"
                target="_blank"
                rel="noreferrer"
              >
                Szkoła Policealna COSINUS w Gnieźnie
              </a>
            </StyledH3>
            Szkoła COSINUS to bezpłatna szkoła dla dorosłych i młodzieży dająca
            możliwość nabycia oraz podniesienia kwalifikacji zawodowych. Zajęcia
            odbywają się w trybie zaocznym, w weekendy. COSINUS posiada jedną z
            najwyższych zdawalaności egzaminów zewnętrznych w kraju.
          </StyledParagraph>
          <StyledH3>OFERUJEMY</StyledH3>
          <StyledList>
            {data.singleOffer.listOfOffers.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </StyledList>
          <ButtonWrapper>
            <StyledButton as={Link} to="/kontakt">
              Skontaktuj się z nami
            </StyledButton>
          </ButtonWrapper>
        </DescriptionWrapper>
      </TextStyledWrapper>
      <OffersWrapper>
        <StyledTitleOffers>nasze realizacje</StyledTitleOffers>
        <SingleOffersGallery offerTitle="Nauka florystyki w szkole COSINUS" />
        <ButtonWrapper>
          <StyledButton
            className="white"
            as={Link}
            to="/galeria/szkola-cosinus"
          >
            Zobacz więcej zdjęć
          </StyledButton>
        </ButtonWrapper>
      </OffersWrapper>
      <ContactFormCom />
      <OffersWrapper>
        <StyledTitleOffers>sprawdź nasze inne oferty</StyledTitleOffers>
        <Offers />
      </OffersWrapper>
    </>
  );
}

export const query = graphql`
  query SzkolaCosinus {
    singleOffer: sanityOffer(slug: { current: { eq: "szkola-cosinus" } }) {
      image {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      id
      listOfOffers
      title
      headline
      description
    }
  }
`;
